import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ExtendedCatalogAttributeService } from '@cl/common/services/extended-catalog-attribute.service';
import { PersistenceService } from '@cl/common/services/persistence.service';
import { UtilsService } from '@cl/common/utils/utils.service';
import { CatalogType } from '@cl/@types/extended-attributes.types';
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';

export enum LIST_ACTION {
  INITIALIZED,
  COLUMNS_TOGGLED,
  FILTERS_UPDATED,
  FILTERS_TOGGLED,
  SCROLLID_UPDATED,
  LOAD_MORE,
  EXPORT_CSV,
  SEARCH_TOGGLED
}
export interface ListSettingsInf {
  filters: {
    isOpen: boolean;
    search: string;
    showColumnSearch: boolean;
    childFiltersQuerys: {
      id: string;
      values: string[];
      date?: { startDate: moment.Moment; endDate: moment.Moment };
    }[];
    searchFieldFilter: {name: string, value: string}[]
  };
  list: {
    rawColumns: any[];
    selectedColumns: any[];
  };
  tags: {
    selectedtags: any[];
  };
  filterTags: {
    // should for any
    // must fot all
    // default should
    condition: 'must' | 'should';
    tags: string[];
  };
  action: LIST_ACTION;
  scrollId: string;
  extendedColumns?:any
}

@Injectable({
  providedIn: 'root',
})
export class ShipmentListStateService {
  readonly rawColumns = [
    {
      prop: 'selected',
      resizable: false,
      canAutoResize: false,
      sortable: false,
      name: '',
      width: 30,
      visible: true,
      headerCheckboxable: true,
      checkboxable: true,
      frozenLeft: true,
    },

    { name: 'Name', prop: 'name', width: 320, minWidth: 160, visible: true, frozenLeft: true, searchable: true },
    { name: 'External ID', prop: 'externalId', minWidth: 160, width: 320, visible: true, searchable: true  },
    { name: 'Type', prop: 'entityType', width: 180, minWidth: 160, visible: true },
    { name: 'Catalog Type', prop: 'parentEntityType', minWidth: 160, width: 180, visible: true },
    { name: 'Organization', prop: 'organizationName', minWidth: 160, width: 150, visible: true },
    {
      name: 'Status',
      prop: 'statusWithAssetCount',
      minWidth: 160, width: 200,
      visible: true,
    },
    { name: 'Sub Status', prop: 'subStatusCode', minWidth: 160, width: 200, visible: false },
    {
      name: 'Composite Shipment Name',
      prop: 'compositeShipmentName',
       minWidth: 160, width: 200,
      visible: true,
    },
    {
      name: 'Composite Shipment ID',
      prop: 'compositeShipmentExtId',
       minWidth: 160, width: 200,
      visible: true,
    },

    { name: 'Direction', prop: 'direction',  minWidth: 160, width: 200, visible: true, searchable: true },
    { name: 'Shipper Name', prop: 'shipperName',  minWidth: 160, width: 200, visible: true, searchable: true },
    {
      name: 'Tracking ID',
      prop: 'trackingNumbers',
       minWidth: 160, width: 200,
      visible: true,
      searchable: true
    },
    { name: 'Carrier Name', prop: 'carrierName',  minWidth: 160, width: 200, visible: true, searchable: true },
    {
      name: 'Customer Name',
      prop: 'custName',
       minWidth: 160, width: 200,
      visible: true,
    },
    {
      name: 'Actual Departure',
      prop: 'actualDepTime',
       minWidth: 160, width: 200,
      visible: true,
    },
    {
      name: 'Actual Arrival',
      prop: 'actualArrivalTime',
       minWidth: 160, width: 200,
      visible: true,
    },
    {
      name: 'Planned Departure',
      prop: 'plannedDeparture',
       minWidth: 160, width: 200,
      visible: true,
    },
    {
      name: 'Planned Arrival',
      prop: 'plannedArrival',
       minWidth: 160, width: 200,
      visible: true,
    },
    { name: 'Completed Time', prop: 'completedTime',  minWidth: 160, width: 200, visible: true },
    { name: 'ETA', prop: 'eta',  minWidth: 160, width: 200, visible: true },
    { name: 'Route', prop: 'routeName',  minWidth: 160, width: 200, visible: true },
    { name: 'Origin', prop: 'originAreaName',  minWidth: 160, width: 200, visible: true, searchable: true },
    { name: 'Destination', prop: 'destinationAreaName',  minWidth: 160, width: 200, visible: true },
    {
      name: 'Device Profile',
      prop: 'sensorProfile',
       minWidth: 160, width: 200,
      visible: true,
    },
    { name: 'Asset Count', prop: 'assetCount',  minWidth: 160, width: 200, visible: true },
    {
      name: 'Master AWB',
      prop: 'mawb',
       minWidth: 160, width: 200,
      visible: true,
    },
    { name: 'Rule Set', prop: 'ruleSetName',  minWidth: 160, width: 200, visible: true },
    {
      name: 'Direct to Residence',
      prop: 'directToResidence',
       minWidth: 160, width: 200,
      visible: true,
      searchable: true
    },
    { name: 'Order Number', prop: 'orderNum',  minWidth: 160, width: 200, visible: true, searchable: true },
    {
      name: 'Customer Code',
      prop: 'custCode',
       minWidth: 160, width: 200,
      visible: true,
      searchable: true
    },
    {
      name: 'Customer Ref Number',
      prop: 'custOrdRef',
       minWidth: 160, width: 200,
      visible: true,
      searchable: true
    },
    // {
    //   name: 'Carrier Name',
    //   prop: 'carrierCode',
    //    minWidth: 160, width: 200,
    //   visible: true,
    //   searchable: true
    // },
    {
      name: 'Recipient Name',
      prop: 'recipientName',
       minWidth: 160, width: 200,
      visible: true,
      searchable: true
    },
    {
      name: 'Mode of Transport',
      prop: 'modeOfTransport',
       minWidth: 160, width: 200,
      visible: true,
      searchable: true
    },
    // {
    //   name: 'Tags',
    //   prop: 'tagsHtml',
    //   width: 250,
    //   minWidth: 160,
    //   visible: 'true',
    // },
    { name: 'Last Milestone', prop: 'mileStoneSubMileStone',  minWidth: 160, width: 200, visible: true },
    {
      name: 'Last Milestone Message',
      prop: 'mileStoneMessage',
       minWidth: 160, width: 200,
      visible: true,
    },
    {
      name: 'Last Carrier Update At',
      prop: 'lastCarrierUpdateAt',
       minWidth: 160, width: 200,
      visible: true,
    },
    // { name: 'Tags', prop: 'tags',  minWidth: 160, width: 200, visible: true },
  ];
  readonly dtpRestrictedFields = ['destinationAreaName', 'originAreaName', 'shipperName', 'custName', 'ruleSetName', 'routeName', 'organizationName'];
  dateColumns = [{ name: 'Created At', prop: 'createdAt',  minWidth: 160, width: 200, visible: true },
  { name: 'Created By', prop: 'createdBy',  minWidth: 160, width: 200, visible: true },
  { name: 'Modified At', prop: 'modifiedAt',  minWidth: 160, width: 200, visible: true },
  { name: 'Modified By', prop: 'modifiedBy',  minWidth: 160, width: 200, visible: true }]
  selectedColumns = [...this.rawColumns].filter((col: any) => col.visible);
  selectedTags: any = [];
  private shipmentSettings: ListSettingsInf = {
    filters: {
      childFiltersQuerys: [],
      searchFieldFilter: [],
      search: '',
      isOpen: true,
      showColumnSearch: false
    },
    list: {
      rawColumns: [...this.rawColumns],
      selectedColumns: [...this.selectedColumns],
    },
    tags: {
      selectedtags: [...this.selectedTags],
    },
    filterTags: {
      condition: 'should',
      tags: [],
    },
    action: LIST_ACTION.INITIALIZED,
    scrollId: '',
    extendedColumns:[]
  };

  listSettingsObservable = new BehaviorSubject<ListSettingsInf>(this.settings);
  readonly catalogType: string = 'shipment';
  private shipmentMultiCatalogTypesWithCDM:CatalogType[] = [];

  public isCSActive = false;
  selectedRowsForCS = new BehaviorSubject<any[]>([]);

  constructor(
      private extendedattributeService: ExtendedCatalogAttributeService,
      private persistenceStore: PersistenceService,
      private _utils: UtilsService,
      private formateDatePipe: FormateDatePipe  
    ) {}

  public get settings() {
    return { ...this.shipmentSettings };
  }

  public changeCSActiveState(state:boolean){
    this.isCSActive = state
  }

  gridColumnToggle(columns: any[]) {
    columns = columns.filter((col: any) => col.visible);
    this.shipmentSettings.list.selectedColumns = [...columns];
    this.shipmentSettings.action = LIST_ACTION.COLUMNS_TOGGLED;
    this.listSettingsObservable.next({ ...this.shipmentSettings });
  }

  toggleFilters() {
    this.shipmentSettings.filters.isOpen =
      !this.shipmentSettings.filters.isOpen;
    this.shipmentSettings.action = LIST_ACTION.FILTERS_TOGGLED;
    this.listSettingsObservable.next({ ...this.shipmentSettings });
  }

  updateFilters(selectedFilters) {
    this.shipmentSettings.filters.childFiltersQuerys = selectedFilters;
    this.shipmentSettings.action = LIST_ACTION.FILTERS_UPDATED;
    this.listSettingsObservable.next({ ...this.shipmentSettings });
  }

  updateSearchFilters(searchFilters) {
    this.shipmentSettings.filters.searchFieldFilter = searchFilters;
    this.shipmentSettings.action = LIST_ACTION.FILTERS_UPDATED;
    this.listSettingsObservable.next({ ...this.shipmentSettings });
  }

  searchShipments(search: string) {
    this.shipmentSettings.filters.search = search;
    this.shipmentSettings.action = LIST_ACTION.FILTERS_UPDATED;
    this.listSettingsObservable.next({ ...this.shipmentSettings });
  }
  reloadShipmentTable() {
    this.shipmentSettings.action = LIST_ACTION.FILTERS_UPDATED;
    this.listSettingsObservable.next({ ...this.shipmentSettings });
  }
  updateTagFilters(tagsFilter) {
    if (this.shipmentSettings.tags.selectedtags.length == 0) {
      this.shipmentSettings.filters.childFiltersQuerys.push(tagsFilter);
      this.shipmentSettings.action = LIST_ACTION.FILTERS_UPDATED;
      this.listSettingsObservable.next({ ...this.shipmentSettings });
    }
  }

  updateScrollId(scrollId) {
    this.shipmentSettings.scrollId = scrollId;
    this.shipmentSettings.action = LIST_ACTION.SCROLLID_UPDATED;
    this.listSettingsObservable.next({ ...this.shipmentSettings });
  }

  loadMoreRecords() {
    this.shipmentSettings.action = LIST_ACTION.LOAD_MORE;
    this.listSettingsObservable.next({ ...this.shipmentSettings });
  }

  exportShipmentsCSV() {
    this.shipmentSettings.action = LIST_ACTION.EXPORT_CSV;
    this.listSettingsObservable.next({ ...this.shipmentSettings });
  }
  updateTags(tagsChecked) {
    this.shipmentSettings.filterTags = tagsChecked;
    this.shipmentSettings.action = LIST_ACTION.FILTERS_UPDATED;
    this.listSettingsObservable.next({ ...this.shipmentSettings });
  }

  toggleSearchColumns(isOpen?: boolean){
    this.shipmentSettings.filters.showColumnSearch = isOpen !== undefined ? isOpen : !this.shipmentSettings.filters.showColumnSearch;
    this.shipmentSettings.action = LIST_ACTION.SEARCH_TOGGLED;
    this.listSettingsObservable.next({...this.shipmentSettings})
  }

  async getShipmentMultiCatalogTypesWithCDM() {
    if(!this.shipmentMultiCatalogTypesWithCDM.length){      
      this.shipmentMultiCatalogTypesWithCDM = await this.extendedattributeService.getCatalogExtendedMultiColumns(
        this.catalogType
      );
    }
    return [...this.shipmentMultiCatalogTypesWithCDM]
  }

  async getExtendedColumnList() {
    let catalogTypes = [];
    try {
      catalogTypes = await this.getShipmentMultiCatalogTypesWithCDM()
    } catch (error) {
    }

    const extendedColumns: any[] = [];
    catalogTypes.forEach((catalogType) => {
      catalogType.cdmFields.forEach((field) => {
        if (field.group === 'User' && field.isDisplayableOnList === true) {
          if (!extendedColumns.find((col) => col.prop == field.name)) {
            extendedColumns.push({
              name: field.displayLabel,
              prop: field.name,
              width: 200,
              minWidth: 160,
              visible: false,
              searchable: field.searchable,
              type: field.type
            });
          }
        }
      });
    });
    const allColoumns = [...this.rawColumns, ...extendedColumns, ...this.dateColumns];
    const {gridColumns, rawColumns} = this._utils.updateColumnsList(this.catalogType, [...allColoumns]);
    this.shipmentSettings.list.rawColumns = [...rawColumns];
    this.shipmentSettings.extendedColumns  = [...extendedColumns];
    this.gridColumnToggle([...rawColumns]);
  }

  checkSearchColumnExist() {
    if(this.shipmentSettings.filters.searchFieldFilter.length>0){
      return true;
    }else{
      return false
    }
  }

  // This method is using for reset the configuration of shipments whilenavigating away from listing page
  reset(){
    this.shipmentSettings.filters.search = ''
  }

  // This method is ported from shipment listing component.
  getShipementDataForExport(columns: any[], shipmentList: any[]): any[] {
    const systemDefinedDateColumns = [
      'plannedDeparture',
      'plannedArrival',
      'eta',
      'actualArrivalTime',
      'actualDepTime',
      'createdAt',
      'modifiedAt',
      'completedTime',
      'lastCarrierUpdateAt'
    ];

    const systemDefinedBooleanColumns = ['directToResidence'];

    const dateColumns = columns
      .filter((col) => col.type?.toUpperCase() == 'DATE')
      .map((col) => col?.prop);
    const booleanColumns = columns
      .filter((col) => col.type?.toUpperCase() == 'BOOLEAN')
      .map((col) => col?.prop);

    dateColumns.push(...systemDefinedDateColumns);
    booleanColumns.push(...systemDefinedBooleanColumns);

    let filteredData = [];

    shipmentList.forEach((s) => {
      let rowData = {};
      columns.forEach((c) => {
        if (c.name != '') {
          if (c.prop) {
            if (c.prop == 'tagsHtml') {
              rowData[c.prop] =
                s['tags'] && s['tags'].length
                  ? s['tags'].map((e) => e.name).join(',')
                  : '  ';
            } else if (dateColumns.includes(c.prop)) {
              rowData[c.prop] = this.formateDatePipe.transform(s[c.prop]);
            } else if (booleanColumns.includes(c.prop)) {
              if (s[c.prop] == undefined) {
                rowData[c.prop] = '  ';
              } else if (['true', 'false'].includes(String(s[c.prop]))) {
                rowData[c.prop] = String(s[c.prop]) == 'true' ? 'Yes' : 'No';
              } else {
                rowData[c.prop] = s[c.prop];
              }
            } else if (c.prop == 'assetCount') {
              rowData['assetCount'] = s.assets ? s.assets.length : 0;
            } else if (c.prop == 'trackingNumbers') {
              try{
                if(s['trackingNumbers']){
                const trackingNumberArray = JSON.parse(s['trackingNumbers']);
                const uniqueTrackingNumberArray:any = [...new Set(trackingNumberArray)];
                rowData[c.prop] = uniqueTrackingNumberArray.length>0
                  ? uniqueTrackingNumberArray.join(', ')
                  :' ';
                }
              }
              catch(error){
                rowData[c.prop] = s[c.prop] ? s[c.prop] : '  ';
              }
            } else {
              rowData[c.prop] = s[c.prop] ? s[c.prop] : '  ';
            }
          } else {
            rowData[c.prop] = '  ';
          }
        }
      });
      filteredData.push(rowData);
    });
    return filteredData;
  }
    
}
